






























import Vue from 'vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import PasswordInput from '@/components/shared/forms/PasswordInput.vue';
import TextInput from '@/components/shared/forms/TextInput.vue';
import { LoginDto } from '@/dto/login.dto';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';

export default Vue.extend({
  components: {
    SubmitButton,
    PasswordInput,
    TextInput
  },
  props: {
    loggingInExtras: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user: {
        usernameOrEmail: '',
        password: ''
      } as LoginDto,
      errors: {},
      type: 'password',
      eye: 'eye'
    };
  },
  computed: {
    ...mapStores(useUsersStore),
    loggingIn(): boolean {
      return this.usersStore.user.loggingIn;
    }
  },
  methods: {
    async submitLogin() {
      this.$emit('onFormValidated', this.user);
    }
  }
});
