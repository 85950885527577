

















































































































































































































































































import BookingStepPlace from '@/components/bookings/steps/BookingStepPlace.vue';
import BookingStepHeader from '@/components/bookings/BookingStepHeader.vue';
import BookingStepServices from '@/components/bookings/steps/BookingStepServices.vue';
import BookingStepDate from '@/components/bookings/steps/BookingStepDate.vue';
import BookingSummary from '@/components/bookings/BookingSummary.vue';
import BookingStepVerification from '@/components/bookings/steps/BookingStepVerification.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import LoginForm from '@/components/auth/LoginForm.vue';
import UserForm from '@/components/auth/UserForm.vue';
import AddBookingMixin from '@/components/bookings/AddBookingMixin.ts';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { useCustomersStore } from '@/store/customers-store';
import { useBookingsStore } from '@/store/bookings-store';
import { Customer } from '@/model/customer';
import { useLocationsStore } from '@/store/locations-store';
import { Location } from '@/model/location';
import { getMainCollections } from '@/store/common/utilities';
import { Collection } from '@/store/common/collections';
import { useVendorsStore } from '@/store/vendors-store';
import { Vendor } from '@/model/vendor';
import { User } from '@/model/user';
import { BModal } from 'bootstrap-vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof AddBookingMixin>
>).extend({
  components: {
    LoginForm,
    UserForm,
    BookingStepPlace,
    BookingStepHeader,
    BookingStepServices,
    BookingStepDate,
    BookingSummary,
    BookingStepVerification,
    LoadingIndicator,
    SubmitButton
  },
  mixins: [AddBookingMixin],
  data() {
    return {
      selectedlocation: {} as Location | undefined,
      vendorId: null as null | number,
      hasAccount: true,
      user: {} as User,
      isFetchingVendor: false,
      savingBookingAndUser: false
    };
  },
  computed: {
    ...mapStores(
      useUsersStore,
      useCustomersStore,
      useBookingsStore,
      useLocationsStore,
      useVendorsStore
    ),
    isLoggedIn(): boolean {
      return this.usersStore.isLoggedIn;
    },
    loggedInUserName(): string | null {
      return this.usersStore.loggedInUserName;
    },
    userFailure(): boolean {
      return this.usersStore.user.failure;
    },
    userSaving(): boolean {
      return this.usersStore.user.saving;
    },
    userCustomer(): Customer {
      return this.customersStore.currentEntity;
    },
    isFetching(): boolean {
      return this.customersStore.fetching;
    },
    customerFailure(): boolean {
      return this.customersStore.failure;
    },
    vendor(): Vendor {
      return this.vendorsStore.getById(this.vendorId);
    }
  },
  async created() {
    this.isFetchingVendor = true;
    this.vendorId = parseInt(this.$route.params.vendorId, 10);
    this.bookingsStore.selectVendorId(this.vendorId);
    await this.vendorsStore.findOne(this.vendorId);
    this.isFetchingVendor = false;
    await this.checkUserAndCustomer();
    await this.locationsStore.findAll(this.vendorId);
    this.selectedlocation = this.locationsStore.getById(
      this.$route.params.locationId
    );

    // Permet de sortir de la page et de revenir dessus, par ex. pour s'inscrire
    if (
      !(
        this.booking &&
        this.booking.events &&
        this.booking.events[0].vendor_id === this.vendorId &&
        this.booking.events[0].location_id === this.selectedlocation?.id
      )
    ) {
      await this.bookingsStore.setLocation(this.selectedlocation);
      await getMainCollections(this.vendorId, true, [Collection.EMPLOYEES]);
    }

    if (this.step === 1 && this.locations.length === 1) {
      this.bookingsStore.setLocation(this.locations[0]);
      this.bookingsStore.setStep(2);
    }
    this.onCreated(1);
  },
  methods: {
    async checkUserAndCustomer() {
      if (!this.userFailure) {
        const userId = this.usersStore.loggedInUserId;
        if (userId !== null) {
          this.user = await this.usersStore.getUser(userId);
          if (
            !this.user.firstname ||
            !this.user.lastname ||
            !this.user.mobile_phone ||
            !this.user.email
          ) {
            (this.$refs.userFormModal as BModal).show();
          }

          await this.customersStore.getAccount(userId, this.vendorId);
          if (!this.customerFailure) {
            this.selectCustomer(this.userCustomer);
          }
        }
      }
    },
    async submitUserForm(user) {
      await this.usersStore.edit(user);
      (this.$refs.userFormModal as BModal).hide();
    },
    async submitLogin(user) {
      await this.usersStore.login(user);
      await this.checkUserAndCustomer();
    },
    async createAccount(user) {
      await this.usersStore.signUp(user);
      await this.checkUserAndCustomer();
    },
    async save() {
      this.savingBookingAndUser = true;
      if (Object.keys(this.userCustomer).length === 0 && this.vendorId) {
        const customerId = await this.customersStore.addForUser(this.vendorId);
        if (!this.customerFailure) {
          const customer = { id: customerId };
          this.bookingsStore.selectCustomer(customer as Customer);
        }
      }
      this.selectCustomer(this.userCustomer);
      const savedSuccessfully = await this.bookingsStore.save();
      this.savingBookingAndUser = this.saving;
      if (savedSuccessfully) {
        await this.$router.push({ name: 'BookingConfirmation' });
        window.scrollTo(0, 0);
      }
    }
  }
});
